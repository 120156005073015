<template>
  <el-drawer title="绑定微信" v-model="drawer" destroy-on-close size="800px">
    <div class="top_select">
      <div>
        <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span
            style="margin-top: 2px;">刷新</span></el-button>
        <el-input placeholder="请输入企业名称" v-model="listQuery.name" style="width: 160px" size="small"
          @keyup.enter="getList" clearable />
        <el-button type="primary" size="small" @click="getList" style="margin-right: 5px" icon="Search">
          搜索
        </el-button>
      </div>
      <div>
        <!-- <el-button size="small" type="success" plain @click="bindAll()" icon="Connection">批量绑定</el-button> -->
        <el-button size="small" type="primary" plain @click="customMessage()" icon="Position">自定义消息推送</el-button>
      </div>
    </div>
    <el-table :data="tableData" style="width: 100%" border :height="contentStyleObj" v-loading="loading"
      @selection-change="handleSelectChange" stripe>
      <el-table-column align="center" prop="id" type="selection" width="55" />
      <el-table-column prop="comName" label="公司名称" fixed min-width="280">
        <template #default="scope">
          <TagNameCopy :row="scope?.row" nameKey="comName" :needNameJump="false" :showAuthIcon="false">
          </TagNameCopy>
        </template>
      </el-table-column>
      <el-table-column prop="wxNc" label="绑定微信昵称" min-width="200">
      </el-table-column>
      <el-table-column align="center" prop="manager" label="操作" width="150">
        <template #default="scope">
          <el-button size="small" type="success" icon="Connection" @click="bindWechat([scope.row])" plain>绑定</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination v-show="total > 0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit"
        @pagination="getList" />
    </div>
  </el-drawer>
  <el-dialog :close-on-click-modal="false" v-model="dialogFormVisible" title="自定义消息推送" width="500px" append-to-body>
    <el-form size="small" :model="form">
      <el-form-item label="内容:" required>
        <el-input type="textarea" v-model="form.msg"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="createSure" :loading="btnLoading">确 定</el-button>
      </span>
    </template>
  </el-dialog>
  <loginTaxAlert ref="loginTaxAlertRef" title="微信转发助手"></loginTaxAlert>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from "vue"
import { ElMessageBox, ElMessage } from "element-plus"
import axios from "axios"
import { companyUserList, getOrgCompanyList, companyEaSetWxNc } from "@/api/company"
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy"
const { proxy } = getCurrentInstance()
const emit = defineEmits()
const listQuery = ref({
  page: 1,
  limit: 20,
  status: "1",
})
const gridData = ref([])
const tableData = ref([])
const loading = ref(false)
const total = ref(false)
const contentStyleObj = ref({})
const selects = ref([])
const drawer = ref(false)
const form = ref({})
const loginTaxAlertRef = ref()
const dialogFormVisible = ref(false)
const btnLoading = ref(false)
onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(210)
})
const init = () => {
  drawer.value = true
  getList()
}
const getList = () => {
  loading.value = true
  getOrgCompanyList(listQuery.value).then((res) => {
    loading.value = false
    if (res.data.msg == "success") {
      tableData.value = res.data.data.list
      total.value = res.data.data.total
    }
  })
}
//批量绑定
const handleSelectChange = (val) => {
  selects.value = val
}
const bindWechat = (item) => {
  ElMessageBox.prompt("请输入微信昵称/微信群名称", "绑定微信昵称", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    inputValue: item.length == 1 ? item[0].wxNc : '',
    beforeClose: (action, instance, done) => {
      if (action == "confirm") {
        let value = instance.inputValue
        let param = {
          ids: item.map((v) => v.id),
          wxNc: value,
        }
        companyEaSetWxNc(param).then((res) => {
          done()
          if (res.data.msg == "success") {
            ElMessage.success("设置成功")
            getList()
            emit('success')
          }
        })
      } else {
        done()
      }
    },
  })
}
const bindAll = () => {
  if (selects.value.length == 0) {
    ElMessage.warning("请选择需要绑定的公司")
    return
  }
  bindWechat(selects.value)
}
const poper = (row) => {
  gridData.value = []
  companyUserList({ comId: row.id }).then((res) => {
    gridData.value = res.data.data.list
  })
}
//自定义消息推送
const customMessage = () => {
  if (selects.value.length == 0) {
    ElMessage.warning("请选择需要发送消息的公司")
    return
  }
  let fail = selects.value.find(v => !v.wxNc)
  if (fail) {
    proxy.$qzfMessage(`${fail.comName}未绑定微信，请重新选择`, 1)
    return
  }
  form.value = {
    msg: ''
  }
  dialogFormVisible.value = true
}
//发送
const createSure = () => {
  if (!form.value.msg) return ElMessage.warning("请输入内容")
  btnLoading.value = true
  const arr = []
  selects.value.forEach(v => {
    arr.push({
      wxName: v.wxNc,
      msg: form.value.msg
    })
  })
  btnLoading.value = false
  axios
    .post("http://localhost:8002/doTask/wx", arr)
    .then((res) => {
    })
    .catch(function () {
      setTimeout(() => {
        loginTaxAlertRef.value.dialogVisible = true;
      }, 1000);
    });
}
defineExpose({ init })
</script>
<script>
export default {
  name: "wechatMessage"
}
</script>
<style lang="scss" scoped>
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

:deep(.el-textarea__inner) {
  min-height: 150px !important;
}
</style>
