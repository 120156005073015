<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogVisible"
    title="异常公司"
  >
    <div class="btn_right" v-if="type2 != 'kefu'">
      <el-button
        size="small"
        type="primary"
        @click="moveOut"
        icon="List"
        plain
        :disabled="!$buttonStatus('zdjz_ztqc')"
        >账套迁出</el-button
      >
    </div>
    <el-table
      :data="list"
      border
      :height="contentStyleObj"
      v-loading="loading"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        align="center"
        prop="id"
        type="selection"
        width="55"
        v-if="type2 != 'kefu'"
      />

      <el-table-column
        fixed="left"
        label="代账公司名称"
        align="left"
        min-width="220"
        v-if="type2 == 'kefu'"
      >
        <template #default="scope">
          {{ scope.row.orgName }}
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        fixed="left"
        label="公司名称"
        min-width="240"
      >
        <template #default="scope">
          <TagNameCopy :row="scope.row"></TagNameCopy>
        </template>
      </el-table-column>
      <el-table-column label="最后结账账期" align="center" min-width="220">
        <template #default="scope">
          {{ scope.row.curPeriod }}
        </template>
      </el-table-column>
    </el-table>
    <div class="foot">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList"
      />
    </div>
  </el-dialog>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import {
  companyAdminCarryOverList,
  companyCarryOverList,
  testAuto,
} from "@/api/automatic";
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";
import { ElMessage, ElMessageBox } from "element-plus";
const { proxy } = getCurrentInstance();
const listQuery = ref({
  name: "",
  period: "",
  userId: [],
  page: 1,
  limit: 20,
  type: "",
  automatic: 1,
  accountingType: 3,
  orgId: 0,
});

const list = ref([]);
const dialogVisible = ref(false);
const contentStyleObj = ref({});

const loading = ref(false);
const total = ref(0);
const type2 = ref("");
const sels = ref([]);
onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(260);
});
const init = (period, type, id) => {
  listQuery.value.period = period;
  dialogVisible.value = true;
  listQuery.value.orgId = id ? id : 0;
  type2.value = type;
  type ? getKefuList() : getList();
  // getList();
};
const handleSelectionChange = (val) => {
  sels.value = val;
};
const getList = () => {
  loading.value = true;
  companyCarryOverList(listQuery.value).then((res) => {
    loading.value = false;
    if (res.data.msg == "success") {
      list.value = res.data.data.list ? res.data.data.list : [];
      total.value = res.data.data.total;
    }
  });
};
const getKefuList = () => {
  loading.value = true;
  companyAdminCarryOverList(listQuery.value).then((res) => {
    loading.value = false;
    if (res.data.msg == "success") {
      list.value = res.data.data.list ? res.data.data.list : [];
      total.value = res.data.data.total;
    }
  });
};
//账套迁出
const moveOut = () => {
  if (sels.value.length == 0) {
    ElMessage.warning("请选择公司");
    return;
  }
  ElMessageBox.confirm(
    "账套迁出后，将取消本企业自动化记账报税，本账期已扣费企业，扣费不再返还并且会清除所有任务记录，确认迁出？",
    "提示",
    {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }
  ).then(() => {
    let param = {
      comIds: sels.value.map((v) => {
        return v.id;
      }),
      type: "迁出",
    };
    testAuto(param).then((res) => {
      if (res.data.msg == "success") {
        ElMessage.success("迁出成功");
        getList();
      }
    });
  });
};
defineExpose({
  init,
});
</script>

<style scoped lang="scss">
.btn_right {
  text-align: right;
  margin-bottom: 10px;
}
.foot {
  margin-top: 10px;
  margin-bottom: 20px;
  & > div {
    float: right;
  }
}
</style>
